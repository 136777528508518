*, div {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.closer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closer::after, .closer::before {
  content: '';
  display: flex;
  width: 2rem;
  height: 3px;
  background-color: pink;
  transform: rotateZ(45deg);
}

.closer::before {
  transform: rotateZ(-45deg) translate(0, -1px);
  position: absolute;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p, li {
  color: #6F6F6F;
}

html, body {
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

nav {
  position: fixed;
  inset: 0;
  z-index: 99;
  bottom: unset;
  background-color: white;
  box-shadow: 0 1px 12px 1px #0004;
}

.boxed_nav {
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  width: 100%;
}

nav ul {
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
}

nav ul li {
  flex: 1;
  font-size: 0.9rem;
  cursor: pointer;
  color: #333;
  text-align: center;
  line-height: 1.25rem;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a:hover, 
nav ul li a.highlight {
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #E5B8B8;
}

.conference_prog_holder {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 6rem;
}

.conference_prog {
  appearance: none;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  padding: 2rem 4rem;
  margin-bottom: 5rem;
  appearance: none;
  border: none;
  font-weight: bold;
  color: white;
  background-color: #EBADC9;
  border-radius: 0.5rem;
  font-size: 1.05rem;
  transition: all 0.25s ease-in-out;
  text-decoration: none;
}

.conference_prog.reg_button {
  background-color: #2cb8c5;
}

.conference_prog:hover {
  background-color: #2cb8c5;
}

.conference_prog.reg_button:hover {
  background-color: #EBADC9;
}

.hero_image {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: url('/public/baby-baby-feet-bed-325690.jpg');
  background-size: cover;
  position: relative;
  background-position: center;
}

.hero_content {
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.hero_content h2, button {
  text-transform: uppercase;
  text-align: center;
}

.hero_content p {
  color: white;
  text-align: center;
}

.hero_content h2 {
  color: white;
  font-weight: 400;
  font-size: 1.15rem;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

h1 {
  font-size: 3rem;
  color: #E5B8B8;
  text-align: center;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

h2 {
  margin-top: 5rem;
  margin-bottom: 4rem;
  color: #6F6F6F;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
}

.hero_content h1 {
  color: white;
  text-transform: initial;
}

.hero_content button, .msze_button {
  appearance: none;
  border: none;
  background-color: #EBADC9;
  padding: 1.5rem 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: all 0.25s ease-in-out;
  margin-top: 4rem;
}

.msze_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  text-decoration: none;
  padding: 1rem 2rem;
}

.msze_button::after {
  content: "\2192";
  font-size: 2rem;
  color: white;
  display: block;
  transform: translateY(-5px);
  padding: 0;
}

.hero_content button:hover, .msze_button:hover {
  background-color: #E5B8B8;
}

.container {
  margin: 0 auto;
  max-width: 1250px;
  padding: 3rem 0;
}

.flex_container {
  display: flex;
}

.flex_container > div {
  flex: 2;
}

.flex_container .boxed_content {
  background-color: #f7ebf0;
  padding: 4rem 3rem;
  color: #6F6F6F;
  flex: 3;
}

.flex_container .boxed_content h2 {
  margin: 0;
  margin-bottom: 3rem;
}

.flex_container .boxed_content p:last-child {
  margin: 0;
}

p, li {
  line-height: 1.75rem;
}

.card_holder {
  display: flex;
  gap: 1rem;
}

.card_element {
  flex: 1;
  padding: 4rem;
  color: #6f6f6f;
}

.flex_container ul li {
  margin-bottom: 0.5rem;
}

.more_button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 10px;
}

.more_button::before, .more_button::after {
  position: absolute;
  background-color: white;
  height: 3px;
  width: 12px;
  content: '';
  transition: all 0.15s ease-in-out;
  transform: rotate(48deg) translateX(-5px);
}

.more_button::after {
  transform: rotate(-48deg) translateX(5px);
}

.more_button.less_button::before {
  transform: translateY(-9px) rotate(48deg) translateX(5px);
}

.more_button.less_button::after {
  transform: translateY(-9px) rotate(-48deg) translateX(-5px);
}

.image_gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 3rem;
}

.image_card {
  overflow: hidden;
  flex: 1;
  position: relative;
  max-height: 16rem;
}

.image_overlay {
  z-index: 2;
  background-color: #fff9;
  text-align: center;
  position: absolute;
  inset: 0;
  padding: 1.25rem;
  display: flex;
  align-items: center;
}

.event_card {
  flex: 1;
  position: relative;
  height: 16rem;
  overflow: hidden;
}

.hamb_menu {
  width: 25px;
  justify-self: flex-end;
}

.hamb_menu span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #EBADC9;
}
.hamb_menu span:not(:last-child) {
  margin-bottom: 5px;
}

.strong {
  color: white;
  font-weight: bold;
}

.press_content {
  padding: 3rem;
  display: flex;
  max-width: 1250px;
  width: 100%;
  gap: 4rem;
}

.press_content_image {
  flex: 1;
  overflow: hidden;
  max-height: 20rem;
  width: 100%;
}

@media screen and (max-width: 1250px) {

  .press_container.container {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .card_holder {
    flex-direction: column;
    padding: 0 1rem;
  }

  .conference_prog_holder {
    position: relative;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
  }

  .conference_prog {
    margin-bottom: 0;
    padding: 1rem;
  }

  .boxed_nav {
    align-items: center;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .boxed_nav .mobile_menu {
    min-width: 20rem;
    overflow: auto;
    max-height: 98%;
    margin-bottom: 5rem;
    grid-column: 1 / -1;
  }

  .boxed_nav ul {
    flex-direction: column;
    padding: 0;
    height: min-content;
  }

  .boxed_nav ul li {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 2rem;
    padding: 0 1rem;
  }

  p, h2 {
    padding: 0 1rem;  
  }

  .flex_container, .press_content {
    flex-direction: column;
    padding: 0;
  }

  a {
    padding: 1rem;
  }

  .image_gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .image_overlay {
    padding: 0.5rem;
  }

  .image_overlay p {
    padding: 0;
    font-size: 0.9rem;
    line-height: 1.25rem;
  }

  .event_card {
    min-width: 16rem;
  }
}